<template>
  <modalContent
    name="email-attachments"
    ref="email-attachments"
    title="Attachments"
    width="50%"
    close
    :showFooter="true"
    @closed="onClose"
  >
    <template #content>
      <div class="attachments-area p-4 rounded-2xl bg-gray-100" v-if="!attachments.length || addMoreAttachments">
          <div class="flex flex-col gap-4">
              <div class="rounded-2xl w-full bg-primary text-white text-center py-4 mx-auto font-bold cursor-pointer" @click="openFileManager"> Browse Case Files </div>

              <div class="flex px-8 py-4 gap-6 items-center justify-between text-primary">
                  <div class="h-0.5 bg-primary p-0 w-full"></div>
                    OR 
                  <div class="h-0.5 bg-primary p-0 w-full"></div>

              </div>

              <!-- <input @change="fetchFileName($event)" id="mail_attachments" type="file" name="mail_attachments" multiple="multiple" class="hidden" /> -->
              <file-pond name="mail_attachments" id="mail_attachments" ref="pond" credits="false" class-name="my-pond w-full h-full" 
                label-idle='<div class="border-dashed border border-primary rounded-2xl p-8 bg-white flex flex-col text-center cursor-pointer h-full w-full">
                    <?xml version="1.0" encoding="iso-8859-1"?>
                    <svg class="mx-auto" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="20" height="22"
                      viewBox="0 0 480.24 480.24" style="enable-background:new 0 0 480.24 480.24;" xml:space="preserve" stroke="#0d6ad5">
                      <g>
                      
                        <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="-26.7652" y1="557.6453" x2="-26.7652" y2="617.1083" gradientTransform="matrix(8 0 0 -8 454.2417 4943.1631)">
                          <stop  offset="0" style="stop-color:#006DF0"/>
                          <stop  offset="1" style="stop-color:#00E7F0"/>
                        </linearGradient>
                        <path style="fill:#0d6ad5;" d="M384.108,336.24h-95.464c-4.418,0-8-3.582-8-8s3.582-8,8-8h95.464c44.183,0,80-35.817,80-80
                          s-35.817-80-80-80h-1.04c-4.418,0.001-8.001-3.58-8.001-7.999c0-0.362,0.024-0.723,0.073-1.081c0.665-4.945,0.989-9.93,0.968-14.92
                          c0.131-66.274-53.489-120.106-119.763-120.236c-55.126-0.109-103.235,37.355-116.637,90.828c-1.026,3.808-4.63,6.337-8.56,6.008
                          c-3.667-0.389-7.352-0.59-11.04-0.6c-57.438,0-104,46.562-104,104s46.562,104,104,104h72.088c4.418,0,8,3.582,8,8s-3.582,8-8,8
                          h-72.088c-66.274,0-120-53.726-120-120s53.726-120,120-120c1.928,0,3.88,0.056,5.904,0.16
                          c21.89-71.85,97.882-112.351,169.732-90.46c57.318,17.463,96.454,70.381,96.364,130.3c0,2.848-0.088,5.6-0.248,8.312
                          c52.847,4.271,92.226,50.574,87.955,103.421C475.785,297.839,434.136,336.25,384.108,336.24z" stroke-width="20px"/>
                      
                        <linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="-26.7667" y1="557.6453" x2="-26.7667" y2="617.1083" gradientTransform="matrix(8 0 0 -8 454.2417 4943.1631)">
                          <stop  offset="0" style="stop-color:#006DF0"/>
                          <stop  offset="1" style="stop-color:#00E7F0"/>
                        </linearGradient>
                        <path style="fill:#0d6ad5" d="M336.276,245.088l-96.168-96.16l-96.168,96.16l11.32,11.32l76.848-76.856V480.24h16V179.552
                          l76.848,76.856L336.276,245.088z" stroke-width="20px" />
                      </g>
                      <g>
                      </g>
                      <g>
                      </g>
                      <g>
                      </g>
                      <g>
                      </g>
                      <g>
                      </g>
                      <g>
                      </g>
                      <g>
                      </g>
                      <g>
                      </g>
                      <g>
                      </g>
                      <g>
                      </g>
                      <g>
                      </g>
                      <g>
                      </g>
                      <g>
                      </g>
                      <g>
                      </g>
                      <g>
                      </g>
                    </svg>
                    <span class="text-primary"> Drag and Drop File </span>
                    <span class="text-gray-500"> <small>.jpg, .png, .svg or .pdf </small></span>
                <div>'
                allow-multiple="true" :accepted-file-types="fileTypes" 
                v-bind:files="myFiles" v-on:init="fetchFileName" />
          </div>
        </div>


        <div class="attachments-area p-4 rounded-2xl bg-white" v-else>
          <div class="flex flex-col gap-4">
            <div class="flex flex-col gap-2 overflow-y-auto" style="height: calc(100% - 100px)">
                <div class="flex justify-between py-2 border-b border-gray-300" v-for="(file, fi) in attachments" :key="fi">
                    <div class="attachment-details flex gap-2">
                        <span class=""> {{ fi + 1 }}. </span>
                        <span class="text-primary cursor-pointer"> 
                            {{file.name}}
                        </span>
                    </div>

                    <div class="attachment-actions">
                      <div class="red-cross cursor-pointer p-1 border border-solid border-red-400 rounded-full" @click="removeAttachment(fi)">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 352 512" fill="red" class="h-4 w-4">
                          <path d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"/>
                        </svg>
                      </div>
                    </div>
                </div>
            </div>
          </div>
      </div>
    </template>

    <template #footer>
      <div class="actions flex" v-if="attachments.length > 0 && !addMoreAttachments">
          <del-button class="ml-auto" text="Add" type="primary" @click="onAddMoreAttachments" />
      </div>

      <button type="button" v-else
        class="text-white bg-primary hover:bg-primary-focus font-medium rounded-2xl text-sm px-5 py-2.5 ml-auto relative" 
        @click="onAddFiles()">Add</button>
    </template>
  </modalContent>
</template>

<script>
const modalContent = () => import("@shared/modal-content");
import DelButton from "@shared/components/button";
import axios from "@/axios";

// Import FilePond
import vueFilePond from "vue-filepond";
// Import plugins
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type/dist/filepond-plugin-file-validate-type.esm.js";
import FilePondPluginImagePreview from "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.esm.js";
// Import styles
import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";
// Create FilePond component
const FilePond = vueFilePond(FilePondPluginFileValidateType, FilePondPluginImagePreview);

export default {
  name: "Email-Attachments",
  props: {
    attachments: [],
  },
  data() {
    return {
      myFiles: [],
      addMoreAttachments: false,
      fileTypes: ["image/jpeg", "image/jpg", "image/svg", "image/png", "application/pdf"],
    };
  },
  components: {
    modalContent,
    DelButton,
    FilePond,
  },
  methods: {
    showModal() {
      this.$refs["email-attachments"].showModal();
      this.addMoreAttachments = false;
    },
    onClose() {
      this.$refs["email-attachments"].hideModal();
    },
    openFileManager() {
        this.$emit("file-manager");
    },
    handleFilePondInit() {
        this.$refs.pond.getFiles();
    },
    fetchFileName() {
        this.$refs.pond.getFiles();
    },
    onAddFiles() {
      if (this.$refs.pond?.getFiles()?.length > 0) {
        const files = this.$refs.pond.getFiles().filter((file) => this.fileTypes.includes(file.file.type));
        if (files.length) 
          this.$emit("change:file", files?.map((file) => file.file) );
        else
          this.$toast.error("Please select a svg , png, jpg, or pdf")
      } else
        this.$toast.error("Please select files to upload");
    },
    onAddMoreAttachments() {
      this.addMoreAttachments = true;
    },
    async onFileOpen(details) {
        try {
            const { data } = await axios.get(`/case-file-manager/get-file-url/${details.file_id}`);
            window.open(data.url, "_blank");
        } catch (error) {
            console.log(error, "[+] failed to fetch file");
        }
    },
    removeAttachment(index) {
      this.$emit("remove-attachment", index)
    }
  },
  computed: {
    isFooter() {
      return (this.attachments.length > 0 && !this.addMoreAttachments);
    }
  },
};
</script>
<style lang="scss">

@import '@/assets/mixins/mixins.scss';

.attachments-area {
  .filepond--root {
    height: 250px;
    // overflow-y: auto;
    margin: 0px;
    @include scrollBar;
  }
  .filepond--panel-root {
    background-color: unset;
  }
  .filepond--root * {
    // height: auto;
    // padding: 0 ;
  }
  .filepond--drop-label {
    // position: unset;
    width: 100%;
    height: auto;
    padding: 0;
    background: #f3f4f6;
    display: block;
    label {
      width: 100%;
      height: 100%;
      padding: 0;
      margin: auto;
      .p-8 {
        padding: 2rem;
      }
    }
  }
  .filepond--list-scroller {
    top: 12px;
    margin: 0px;
  }
  .filepond--list {
    left:0px;
    width: 100%;
  }
}
</style>
